import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  Tooltip,
  IconButton,
} from "@mui/material";
import logo from "../Images/logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

// const pages = ["Home", "Product", "Quality", "About", "Contact"];

const Footer = () => {
  return (
    <>
      <AppBar
        style={{
          // display: "flex",
          // flexDirection: "column",
          position: "relative",
          minHeight: "1rem",
        }}
        component="footer"
        color="default"
      >
        <Box
          id="main-logo-hide"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "3rem 0 1rem 0",
          }}
        >
          <Link to={"/"}>
            <Box
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 50,
                width: 280,
                // cursor: "pointer",
              }}
            ></Box>
          </Link>
        </Box>

        {/* If you want to show navigation button in footer so you can uncomment below Toolbox block */}

        {/* <Toolbar style={{ justifyContent: "center" }}>
          <Box display="flex" justifyContent="flex-end">
            {pages.map((page, index) => (
              <Button
                variant="text"
                key={index}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                }}
              >
                <NavLink
                  style={{ textDecoration: "none", color: "#2c3e50" }}
                  to={`/${page}`}
                >
                  {page}
                </NavLink>
              </Button>
            ))}
          </Box>
        </Toolbar> */}

        <Toolbar style={{ justifyContent: "center" }}>
          <Box>
            <Tooltip title="Facebook" placement="top">
              <IconButton href="/">
                <FacebookIcon
                  sx={{
                    fontSize: "2.5rem",
                    color: "#385898",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Linkedin" placement="top">
              <IconButton
                href="https://www.linkedin.com/company/textile-channel/"
                target="_blank"
              >
                <LinkedInIcon
                  sx={{
                    fontSize: "2.5rem",
                    color: "#0A66C2",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="info@textilechannel.com">
              <IconButton href="mailto:info@textilechannel.com">
                <EmailIcon
                  sx={{
                    fontSize: "2.5rem",
                    color: "#e74c3c",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
        <Toolbar style={{ justifyContent: "center" }}>
          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
            &copy; 2002 - {new Date().getFullYear()} | All rights reserved
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Footer;
