import React, { useEffect } from "react";
import Slider from "./Slider";
import ProductCards from "./ProductCards";
import { Box, Button, Divider, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  useEffect(() => {
    window.AOS.init({
      // Initialization
      duration: 1500,
    });
  });
  return (
    <>
      <Container>
        <div data-aos="fade-right">
          <Slider />
        </div>

        <div data-aos="zoom-out-right">
          <Typography
            variant="h3"
            mt={2}
            mb={1}
            style={{
              textAlign: "center",
              fontSize: "2rem",
            }}
          >
            Our <span style={{ color: "rgb(95, 163, 210)" }}>Stall</span> at
            Heimtextil - 2024
          </Typography>
        </div>
        <Box data-aos="zoom-in-down">
          <img
            src="https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/hemtex-stall.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1673340087086"
            alt="hemtextil stall"
            style={{
              height: "auto",
              width: "100%",
              padding: "0% 5%",
            }}
          />
        </Box>
        <Box sx={{ padding: 1 }}>
          <div data-aos="zoom-in-down">
            <Typography
              variant="h1"
              mt={5}
              style={{
                textAlign: "center",
                fontSize: "2.8rem",
              }}
            >
              <span style={{ color: "rgb(95, 163, 210)" }}>Who</span> we are?
            </Typography>
            <Typography
              variant="body1"
              mt={5}
              mb={15}
              style={{ textAlign: "justify" }}
            >
              Textile Channel was founded in 2002 and has been since. A business
              grew based on a lot of research and more. Decades of knitted
              jersey manufacturing and furnishing expertise, Woven garments and
              woven bedclothes and key home textile products. The textile
              channel has become a multifaceted entity. As a result I thrive and
              provide now Global standards
              <br />
              <br /> We follows strict day-to-day procedures, focusing on staff
              well-being and client happiness, because it is a nice sight to see
              pleasure on the faces of everyone with whom the company is
              affiliated, and it has always aided the company in reaching
              greater heights since its inception.
              <Button
                variant="text"
                size="small"
                style={{ textDecoration: "none" }}
              >
                <Link to={"About"}>
                  <HashLink to="About/#about">About Us</HashLink>
                </Link>
              </Button>
            </Typography>
          </div>

          {/* here i using product-cards component in a Box tag */}

          <ProductCards />
        </Box>
        <Divider sx={{ mb: 7 }} />
      </Container>
    </>
  );
};

export default Home;
