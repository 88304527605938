import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slider.css";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
// import FittedSheets from "../../Images/imagesForHomePage/Slider/live-images/1.jpg";
// import Garments from "../../Images/imagesForHomePage/Slider/live-images/2.jpg";
// import Towel from "../../Images/imagesForHomePage/Slider/live-images/3.jpg";
// import Bathrobe from "../../Images/imagesForHomePage/Slider/live-images/4.jpg";
const fadeImages = [
  {
    url:
      "https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Slider/1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669702683061",
    title: "Knitted Fitted Sheets",
    description:
      "Our speciality is a complete line of high-quality single jersey and terry fitted sheets. We have a broad variety of sizes in white and colours to choose from.",
  },
  {
    url:
      "https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Slider/2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669267881084",
    title: "Knitted Garments",
    description:
      "Textile Channel is a leading apparel company in Pakistan having expertise & machinery capable of transforming customer ideas into superior quality knitted garments, which are taken as the most famous brand in the world.",
  },
  {
    url:
      "https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Slider/3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669267881083",
    title: "Towel",
    description:
      "We are a Normal to high-end Quality towel manufacturer, weaving towels up to 600 gsm on Dyna Terry / Air jet / Shuttle less looms.",
  },
  {
    url:
      "https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Slider/4.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669267881467",
    title: "Bathrobe",
    description:
      "Our Terry Bathrobes are made with brilliant reactive colours. Dobby and Jacquard bathmats in white and other colours are also available in a variety of sizes and quality levels.",
  },
];
const Slider = () => {
  return (
    <Container sx={{ mt: 1.5 }}>
      <Fade indicators={true}>
        {fadeImages.map((fadeImage, index) => (
          <Box key={index} id="sliderText">
            <Card
              id="sliderCard"
              sx={{
                maxWidth: "30%",
                borderRadius: 8,
                backgroundColor: "#d1d8e0",
                marginRight: "10px",
              }}
            >
              <CardContent>
                <Typography
                  variant="h2"
                  component="h2"
                  sx={{
                    fontWeight: "bold",
                    mt: 1,
                    mb: 5,
                    fontSize: 33,
                    textAlign: "center",
                  }}
                >
                  {fadeImage.title}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: 15, textAlign: "center" }}
                >
                  {fadeImage.description}
                </Typography>
              </CardContent>
            </Card>
            <Box id="sliderImage">
              <img
                src={fadeImage.url}
                alt={fadeImage.title}
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: 10,
                  maxHeight: 500,
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        ))}
      </Fade>
    </Container>
  );
};
export default Slider;
