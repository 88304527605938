import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ResponsiveAppBar from "./components/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import ScrollToTop from "react-scroll-to-top";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";

// Below imports are using Lazy Loading for faster the website performance

const Product = lazy(() => import("./components/Product/Product"));
const Quality = lazy(() => import("./components/Quality/Quality"));
const About = lazy(() => import("./components/About/About"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Gallery = lazy(() => import("./components/Gallery/Gallery"));
const FittedSheets = lazy(() => import("./components/Gallery/FittedSheets"));
const Garments = lazy(() => import("./components/Gallery/Garments"));
const Towel = lazy(() => import("./components/Gallery/Towel"));
const Bathrobes = lazy(() => import("./components/Gallery/Bathrobes"));
const ColorShades = lazy(() => import("./components/Gallery/ColorShades"));

// import Product from "./components/Product/Product";
// import Quality from "./components/Quality/Quality";
// import About from "./components/About/About";
// import Contact from "./components/Contact";
// import Gallery from "./components/Gallery/Gallery";
// import FittedSheets from "./components/Gallery/FittedSheets";
// import Garments from "./components/Gallery/Garments";
// import Towel from "./components/Gallery/Towel";
// import Bathrobes from "./components/Gallery/Bathrobes";
// import ColorShades from "./components/Gallery/ColorShades";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f5f6fa",
    },
  },
});

const App = () => {
  return (
    <React.StrictMode>
      <div className="pageContainer">
        <ScrollToTop
          top={20}
          style={{
            backgroundColor: "#b2bec3",
            zIndex: 9999,
            cursor: "pointer",
            margin: -20,
          }}
        />
        <div className="containerWrap">
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <ResponsiveAppBar />
          </ThemeProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="Product"
              element={
                <Suspense
                  fallback={
                    <Typography
                      variant="h1"
                      fontSize={"2rem"}
                      textAlign="center"
                      style={{ margin: "10rem auto" }}
                    >
                      Product Page is on the way...
                    </Typography>
                  }
                >
                  <Product />
                </Suspense>
              }
            />
            <Route
              path="Quality"
              element={
                <Suspense
                  fallback={
                    <Typography
                      variant="h1"
                      fontSize={"2rem"}
                      textAlign="center"
                      style={{ margin: "10rem auto" }}
                    >
                      Quality Page is on the way...
                    </Typography>
                  }
                >
                  <Quality />
                </Suspense>
              }
            />
            <Route
              path="About"
              element={
                <Suspense
                  fallback={
                    <Typography
                      variant="h1"
                      fontSize={"2rem"}
                      textAlign="center"
                      style={{ margin: "10rem auto" }}
                    >
                      About Page is on the way...
                    </Typography>
                  }
                >
                  <About />
                </Suspense>
              }
            />
            <Route
              path="Gallery"
              element={
                <Suspense
                  fallback={
                    <Typography
                      variant="h1"
                      fontSize={"2rem"}
                      textAlign="center"
                      style={{ margin: "10rem auto" }}
                    >
                      Gallery Page is on the way...
                    </Typography>
                  }
                >
                  <Gallery />
                </Suspense>
              }
            >
              <Route
                path="FittedSheets"
                element={
                  <Suspense
                    fallback={
                      <Typography
                        variant="h1"
                        fontSize={"2rem"}
                        textAlign="center"
                        style={{ margin: "10rem auto" }}
                      >
                        FittedSheets Page is on the way...
                      </Typography>
                    }
                  >
                    <FittedSheets />
                  </Suspense>
                }
              />
              <Route
                path="Garments"
                element={
                  <Suspense
                    fallback={
                      <Typography
                        variant="h1"
                        fontSize={"2rem"}
                        textAlign="center"
                        style={{ margin: "10rem auto" }}
                      >
                        Garments Page is on the way...
                      </Typography>
                    }
                  >
                    <Garments />
                  </Suspense>
                }
              />
              <Route
                path="Towel"
                element={
                  <Suspense
                    fallback={
                      <Typography
                        variant="h1"
                        fontSize={"2rem"}
                        textAlign="center"
                        style={{ margin: "10rem auto" }}
                      >
                        Towel Page is on the way...
                      </Typography>
                    }
                  >
                    <Towel />
                  </Suspense>
                }
              />
              <Route
                path="Bathrobes"
                element={
                  <Suspense
                    fallback={
                      <Typography
                        variant="h1"
                        fontSize={"2rem"}
                        textAlign="center"
                        style={{ margin: "10rem auto" }}
                      >
                        Bathrobes Page is on the way...
                      </Typography>
                    }
                  >
                    <Bathrobes />
                  </Suspense>
                }
              />
              <Route
                path="ColorShades"
                element={
                  <Suspense
                    fallback={
                      <Typography
                        variant="h1"
                        fontSize={"2rem"}
                        textAlign="center"
                        style={{ margin: "10rem auto" }}
                      >
                        ColorShades Page is on the way...
                      </Typography>
                    }
                  >
                    <ColorShades />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="Contact"
              element={
                <Suspense
                  fallback={
                    <Typography
                      variant="h1"
                      fontSize={"2rem"}
                      textAlign="center"
                      style={{ margin: "10rem auto" }}
                    >
                      Contact Page is on the way...
                    </Typography>
                  }
                >
                  <Contact />
                </Suspense>
              }
            />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </React.StrictMode>
  );
};

export default App;
