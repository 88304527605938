import * as React from "react";
import {
  Divider,
  MenuItem,
  Tooltip,
  Button,
  Container,
  Menu,
  Typography,
  IconButton,
  Toolbar,
  Box,
  AppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";
import { NavLink } from "react-router-dom";

const pages = ["Home", "Product", "Quality", "About", "Contact"];
const gallery = [
  { link: "FittedSheets", display: "Fitted Sheets" },
  { link: "Garments", display: "Garments" },
  { link: "Towel", display: "Towel" },
  { link: "Bathrobes", display: "Bathrobes" },
  { link: "ColorShades", display: "Color / Shades" },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElGallery, setAnchorElGallery] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenGalleryMenu = (event) => {
    setAnchorElGallery(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseGalleryMenu = () => {
    setAnchorElGallery(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            id="main-logo-hide"
            style={{
              flexGrow: 1,
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            <Link to={"/"}>
              <Box
                sx={{
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  mt: -1,
                  height: 50,
                  width: 280,
                }}
              ></Box>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Tooltip title="Show Pages">
              <IconButton
                size="large"
                aria-label="account of current Gallery"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Button variant="text" textAlign="center" key={index}>
                    <NavLink
                      style={{
                        textDecoration: "none",
                        color: "#2c3e50",
                      }}
                      to={`/${page}`}
                    >
                      {page}
                    </NavLink>
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="h1"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <Link to={"/"}>
              <Box
                id="main-logo-in-mobile"
                sx={{
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: 40,
                  width: 200,
                }}
              ></Box>
            </Link>
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {pages.map((page, index) => (
              <Button
                variant="text"
                key={index}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  fontSize: "0.8rem",
                  display: "block",
                }}
              >
                <NavLink
                  style={{ textDecoration: "none", color: "#2c3e50" }}
                  to={`/${page}`}
                >
                  {page}
                </NavLink>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Show Gallery">
              <IconButton
                size="large"
                onClick={handleOpenGalleryMenu}
                sx={{
                  p: 0,
                  mr: 1.5,
                  ml: 1,
                  display: { xs: "flex", md: "flex" },
                  flexGrow: 1,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <CollectionsIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElGallery}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElGallery)}
              onClose={handleCloseGalleryMenu}
            >
              {gallery.map((gallery, index) => (
                <MenuItem key={index} onClick={handleCloseGalleryMenu}>
                  <Divider />
                  <Button variant="text">
                    <NavLink
                      style={{ textDecoration: "none", color: "#2c3e50" }}
                      to={`/Gallery/${gallery.link}`}
                    >
                      {gallery.display}
                    </NavLink>
                  </Button>
                  <Divider />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
