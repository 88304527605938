import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  CardActions,
} from "@mui/material";
import { HashLink } from "react-router-hash-link";
// import img1 from "../../Images/imagesForHomePage/Product/fitted-3x2.jpg";
// import img2 from "../../Images/imagesForHomePage/Product/garment-3x2.jpg";
// import img3 from "../../Images/imagesForHomePage/Product/towel-3x2.jpg";
// import img4 from "../../Images/imagesForHomePage/Product/bathrobe-3x2.jpg";
import { NavLink } from "react-router-dom";

const ProductCards = () => {
  useEffect(() => {
    window.AOS.init({
      // Initialization
      duration: 1500,
    });
  });
  return (
    <>
      <Typography variant="h4">Our Products:</Typography>
      <Box
        sx={{
          mb: 5,
          display: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="space-around"
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid data-aos="zoom-in-up" item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Card
              sx={{
                maxWidth: 450,
                minHeight: 500,
                mt: 5,
                borderRadius: 8,
                backgroundColor: "#d1d8e0",
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image="https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Products/fitted-3x2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669267884382"
                alt="green iguana"
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: 3,
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Knitted Fitted Sheets
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our specialty is a complete line of high-quality single jersey
                  and terry fitted sheets.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/Gallery/FittedSheets"
                  >
                    Photos
                  </NavLink>
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button size="small">
                  <HashLink
                    style={{ textDecoration: "none" }}
                    to="/Product/#knitted-fitted-sheets"
                  >
                    Learn More
                  </HashLink>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid data-aos="zoom-in-up" item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Card
              sx={{
                maxWidth: 450,
                minHeight: 500,
                mt: 5,
                borderRadius: 8,
                backgroundColor: "#d1d8e0",
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image="https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Tc-2024/6.jpg?updatedAt=1703138538370"
                alt="green iguana"
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: 3,
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Knitted Garments
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Many knids of knitted Garments are manufactured and exported
                  by Textile Channel.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/Gallery/Garments"
                  >
                    Photos
                  </NavLink>
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button size="small">
                  <HashLink
                    style={{ textDecoration: "none" }}
                    to="/Product/#garments"
                  >
                    Learn More
                  </HashLink>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid data-aos="zoom-in-up" item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Card
              sx={{
                maxWidth: 450,
                minHeight: 500,
                mt: 5,
                borderRadius: 8,
                backgroundColor: "#d1d8e0",
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image="https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Products/towel-3x2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669267885239"
                alt="green iguana"
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: 3,
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Towel
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Dyna Terry / Air Jet / Shuttleless looms in a variety of
                  colours with a quality of up to 600 gsm.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/Gallery/Towel"
                  >
                    Photos
                  </NavLink>
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button size="small">
                  <HashLink
                    style={{ textDecoration: "none" }}
                    to="/Product/#towel"
                  >
                    Learn More
                  </HashLink>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid data-aos="zoom-in-up" item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Card
              sx={{
                maxWidth: 450,
                minHeight: 500,
                mt: 5,
                borderRadius: 8,
                backgroundColor: "#d1d8e0",
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image="https://ik.imagekit.io/le75hnunv5/TC-images/cdn-images/Products/bathrobe-3x2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669267882775"
                alt="green iguana"
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: 3,
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Bathrobes
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Textile Channel also sells luxurious bathrobes made of
                  high-quality cloth weighing 400 to 550 grammes per square
                  metre.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/Gallery/Bathrobes"
                  >
                    Photos
                  </NavLink>
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button size="small">
                  <HashLink
                    style={{ textDecoration: "none" }}
                    to="/Product/#bathrobe"
                  >
                    Learn More
                  </HashLink>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProductCards;

// Below cardData array is for mapping the products card if you need it. I'm okay with hard coding.

// const cardData = [
//   {
//     id: 1,
//     title: `Knitted Fitted Sheets`,
//     imageUrl: `${img1}`,
//     description: `Our specialty is a complete line of high-quality single jersey and terry fitted sheets.`,
//   },
//   {
//     id: 2,
//     title: `Knitted Garments`,
//     imageUrl: `${img2}`,
//     description: `Many knids of knitted Garments are manufactured and exported by Textile Channel.`,
//   },
//   {
//     id: 3,
//     title: `Towel`,
//     imageUrl: `${img3}`,
//     description: `Dyna Terry / Air Jet / Shuttleless looms in a variety of colours with a quality of up to 600 gsm.`,
//   },
//   {
//     id: 4,
//     title: `Bathrobes`,
//     imageUrl: `${img4}`,
//     description: `Textile Channel also sells opulent bathrobes made of high-quality cloth weighing 400 to 550 grammes per square metre.`,
//   },
// ];
